import React from "react";
import Titleicon from "../assets/images/titleicon.png";

function Hotelservicecard() {
  return (
    <div className="container beneficioscard_wrap hotelservicecard_wrap">
      <div className="row">
        <div className="col-12 beneficioscard_heading">
          <h2>Desde estancias privadas hasta hoteles de encanto,</h2>
          <h3>
            recibe beneficios exclusivos ev +1.800 hoteles
            <br /> cuidadosamente seleccionados alredecor del mundo
          </h3>
          <p>Disfruta de estos privilegios unicos en cada una de tus estadias:</p>
        </div>
        <div className="hotel_service">
          <div className="hotel_service_card">
            <div className="hotel_service_card_img">
              <img src={Titleicon} alt="hotel_service_card_img" />
            </div>
            <div className="hotel_service_card_info">
              <h4>Check in anticipado</h4>
              <p>(segun disponibilidad)</p>
            </div>
          </div>
          <div className="hotel_service_card">
            <div className="hotel_service_card_img">
              <img src={Titleicon} alt="hotel_service_card_img" />
            </div>
            <div className="hotel_service_card_info">
              <h4>Check in anticipado</h4>
              <p>(segun disponibilidad)</p>
            </div>
          </div>
          <div className="hotel_service_card">
            <div className="hotel_service_card_img">
              <img src={Titleicon} alt="hotel_service_card_img" />
            </div>
            <div className="hotel_service_card_info">
              <h4>Check in anticipado</h4>
              <p>(segun disponibilidad)</p>
            </div>
          </div>
          <div className="hotel_service_card">
            <div className="hotel_service_card_img">
              <img src={Titleicon} alt="hotel_service_card_img" />
            </div>
            <div className="hotel_service_card_info">
              <h4>Check in anticipado</h4>
              <p>(segun disponibilidad)</p>
            </div>
          </div>
          <div className="hotel_service_card">
            <div className="hotel_service_card_img">
              <img src={Titleicon} alt="hotel_service_card_img" />
            </div>
            <div className="hotel_service_card_info">
              <h4>Check in anticipado</h4>
              <p>(segun disponibilidad)</p>
            </div>
          </div>
          <div className="hotel_service_card">
            <div className="hotel_service_card_img">
              <img src={Titleicon} alt="hotel_service_card_img" />
            </div>
            <div className="hotel_service_card_info">
              <h4>Check in anticipado</h4>
              <p>(segun disponibilidad)</p>
            </div>
          </div>
          <div className="hotel_service_card">
            <div className="hotel_service_card_img">
              <img src={Titleicon} alt="hotel_service_card_img" />
            </div>
            <div className="hotel_service_card_info">
              <h4>Check in anticipado</h4>
              <p>(segun disponibilidad)</p>
            </div>
          </div>
          <div className="hotel_service_card">
            <div className="hotel_service_card_img">
              <img src={Titleicon} alt="hotel_service_card_img" />
            </div>
            <div className="hotel_service_card_info">
              <h4>Check in anticipado</h4>
              <p>(segun disponibilidad)</p>
            </div>
          </div>
          <div className="hotel_service_card">
            <div className="hotel_service_card_img">
              <img src={Titleicon} alt="hotel_service_card_img" />
            </div>
            <div className="hotel_service_card_info">
              <h4>Check in anticipado</h4>
              <p>(segun disponibilidad)</p>
            </div>
          </div>
          <div className="hotel_service_card">
            <div className="hotel_service_card_img">
              <img src={Titleicon} alt="hotel_service_card_img" />
            </div>
            <div className="hotel_service_card_info">
              <h4>Check in anticipado</h4>
              <p>(segun disponibilidad)</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hotelservicecard;
