import React from "react";
import Layout from "../components/Layout";
import Header from "../components/Header";
import Servicio from "../components/Servicio";
import Footer from "../components/Footer";
import Serviciocardslider from "../components/Serviciocardslider";
import Beneficioscard from "../components/Beneficioscard";
import Beneficioscontact from "../components/Beneficioscontact";
import Hotelservicecard from "../components/Hotelservicecard";

const hotelservices = () => {
  return (
    <Layout>
      <Header />
      <Servicio />
      <Serviciocardslider />
      <Hotelservicecard />
      <Beneficioscontact />
      <Footer />
    </Layout>
  );
};

export default hotelservices;
